import React from 'react'


const Footer = () => {
  return (

    <footer className="flex flex-row justify-between  bg-[#0a192f] ">
        <p  style={{ whiteSpace: 'pre' }} className='text-gray-300'>&copy; 2024    Designed & Developed by Ian Fung</p>
  
    </footer>
  )
}

export default Footer
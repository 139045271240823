import Evo from '../assets/projects/evo11.png'
import Youtube from '../assets/projects/youtube.jpg'
import VR from '../assets/projects/VR.jpg'
import outdoorwood from '../assets/projects/outdoorwood.jpg'

export const data=[
    {
        id:1,
        name:"Experience VR (Vancouver) official site",
        image:VR,
        github:"",
        live:"https://experiencevrvancouver.com",
    },
    {
        id:2,
        name:"Outdoor Wood Workshop official site",
        image:outdoorwood,
        github:"",
        live:"https://outdoorwoodworkshop.com",
    },
    {
        id:3,
        name:"TDF Fitness official site",
        image:Evo,
        github:"",
        live:"https://tdffitness.fit/",
    },
    
    {
        id:4,
        name:"Youtube Clone - personal project",
        image:Youtube,
        github:"",
        live:"https://youtube-clone-9s7o.onrender.com",
    },


]